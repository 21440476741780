const StickyNavbar = (selector) => {
    if (selector == null) return;

    window?.addEventListener('scroll', (event) => {
        const target = event.currentTarget as Window;

        if (target.scrollY > 250) {
            document.body.style.paddingTop = `${selector.offsetHeight}px`;
            selector.classList.add('navbar-stuck');
        } else {
            document.body.style.paddingTop = '';
            selector.classList.remove('navbar-stuck');
        }
    }, {
        capture: true,
        passive: true
    });
};

const RevealNavbar = (reveal, product) => {
    if (reveal == null) return;

    window?.addEventListener('scroll', (event) => {
        const target = event.currentTarget as Window;

        if (target.scrollY > 1) {
            reveal.classList.add('fixed-top', 'shadow');
        } else {
            reveal.classList.remove('fixed-top', 'shadow');
        }

        if (target.scrollY > 200) {
            reveal.classList.add('none');
            product.classList.remove('none');
            product.classList.add('navbar-stuck');
        } else {
            reveal.classList.remove('none');
            product.classList.add('none');
            product.classList.remove('navbar-stuck');
        }
    }, {
        capture: true,
        passive: true
    });
};

export {
    StickyNavbar,
    RevealNavbar
};
