import Axios from 'axios';

const AxiosCallbackError = (error: { message: any; }): string => {
    if (Axios.isAxiosError(error)) {
        console.error('❌ Error : ', error.message as string);

        return error.message as string;
    }

    console.error('❌ An unexpected error occurred : ', error);

    return '❌ An unexpected error occurred !';
};

export default AxiosCallbackError;
