import Lunr from 'lunr';
import Axios from 'axios';
import {
    SlIconButton, SlInput
} from '@shoelace-style/shoelace';

class Search {
    static createSearchPanel(): string {
        return `
            <div class="site-search__overlay"></div>
		    <div id="site-search-panel" class="site-search__panel" role="combobox" aria-expanded="false" aria-owns="site-search-results" aria-activedescendant="">
		        <header class="site-search__header">
		            <sl-input class="site-search__input" type="search" placeholder="Rechercher sur le site ..." aria-autocomplete="list" aria-controls="site-search-results" size="large" clearable>
		                <sl-icon slot="prefix" library="heroicons" name="magnifying-glass"></sl-icon>
		            </sl-input>
		        </header>
		        <div class="site-search__body">
		            <ul id="site-search-results" class="site-search__results" role="listbox" aria-labelledby="site-search-panel"></ul>
		            <div class="site-search__empty">
		                <sl-icon library="heroicons" name="x-circle"></sl-icon>
		                <div class="wf-paragraph--sm wf-weight--semi-bold wf-font--mts wf-mt--1">
		                    Aucun résultat trouvé.
		                </div>
		            </div>
		        </div>
		        <footer class="site-search__footer site-search__footer-message wf-display--none">
		            <small>
		                Nous avons trouvé
		                <span class="wf-weight--semi-bold wf-color--primary">
		                    <span class="site-search__footer-length">12</span> résultats
		                </span> pour vous !
		            </small>
		        </footer>
		        <footer class="site-search__footer">
		            <small><kbd>↑</kbd> <kbd>↓</kbd> naviguer</small>
		            <small><kbd>↲</kbd> choisir</small>
		            <small><kbd>esc</kbd> fermer</small>
		        </footer>
		    </div>`;
    }

    static createSearchResult(
        page: {
			img: string
			title: string
			content: string
		}
    ): string {
        return `
			<div class="site-search__result-image">
				<img src="${page.img}" alt="${page.title}" />
	        </div>
	        <div class="site-search__result__details">
	            <div class="wf-heading--xxs">${page.title}</div>
	            <small class="wf-paragraph--xs">${page.content}</small>
	        </div>
			<div class="site-search__result-icon">
				<sl-icon library="heroicons" name="chevron-right" aria-hidden="true"></sl-icon>
	        </div>`;
    }

    private static async fetchSearchData(): Promise<any> {
        let response = await Axios.get('/api/get/search');
        return await response.data;
    }

    static async init(): Promise<void> {
        /// append the search panel to the body
        const siteSearch = document.createElement('div') as HTMLDivElement;

        siteSearch.classList.add('site-search');
        siteSearch.hidden = true;
        siteSearch.innerHTML = this.createSearchPanel();

        document.body.append(siteSearch);

        const overlay: HTMLDivElement = siteSearch.querySelector(
            '.site-search__overlay'
        ) as HTMLDivElement;
        const panel: HTMLDivElement = siteSearch.querySelector(
            '.site-search__panel'
        ) as HTMLDivElement;
        const input: SlInput = siteSearch.querySelector(
            '.site-search__input'
        ) as SlInput;
        const results: HTMLUListElement = siteSearch.querySelector(
            '.site-search__results'
        ) as HTMLUListElement;
        const length: HTMLDivElement = siteSearch.querySelector(
            '.site-search__footer-message'
        ) as HTMLDivElement;
        const messageLength: HTMLSpanElement = siteSearch.querySelector(
            '.site-search__footer-length'
        ) as HTMLSpanElement;

        const animationDuration = 150;
        const searchDebounce = 200;
        let isShowing = false;
        let searchTimeout: string | number | NodeJS.Timeout | undefined;

        /// load search data from the server
        const data = await this.fetchSearchData();
        const searchIndex = Lunr.Index.load(data.index);
        const { map } = data;

        const updateResults = async (
            query = ''
        ): Promise<void> => {
            try {
                await searchIndex;

                const hasQuery = query.length > 0;

                const searchTokens = query
                    .split(' ')
                    .map((term: string, index: number, arr: string[]) => `${term}${index === arr.length - 1 ? `* ${term}~1` : '~1'}`)
                    .join(' ');

                const matches = hasQuery ? searchIndex.search(`${query} ${searchTokens}`) : [];
                const hasResults = hasQuery && matches.length > 0;

                if (hasResults) {
                    length.classList.remove('wf-display--none');
                    messageLength.innerText = matches.length.toString();
                } else {
                    length.classList.add('wf-display--none');
                    messageLength.innerText = '0';
                }

                siteSearch.classList.toggle('site-search--has-results', hasQuery && hasResults);
                siteSearch.classList.toggle('site-search--no-results', hasQuery && !hasResults);
                panel.setAttribute('aria-expanded', hasQuery && hasResults ? 'true' : 'false');

                results.innerHTML = '';

                matches.forEach((
                    match: {
						ref: string | number
					},
                    index: number
                ): void => {
                    const page = map[match.ref];
                    const li: HTMLLIElement = document.createElement(
                        'li'
                    ) as HTMLLIElement;
                    const a: HTMLAnchorElement = document.createElement(
                        'a'
                    ) as HTMLAnchorElement;

                    a.setAttribute('role', 'option');
                    a.setAttribute('href', page.url);
                    a.setAttribute('id', `search-result-item-${match.ref}`);

                    a.innerHTML = this.createSearchResult(page);

                    li.classList.add('site-search__result');

                    li.setAttribute('aria-selected', index === 0 ? 'true' : 'false');

                    li.appendChild(a);

                    results.appendChild(li);
                });
            } catch {
                // Ignore errors
            }
        };

        const hide = async (): Promise<void> => {
            document.body.style.overflow = 'auto';

            isShowing = false;
            document.body.classList.remove('site-search-visible');

            await Promise.all([
                panel.animate(
                    [
                        {
                            opacity: 1,
                            transform: 'scale(1)'
                        },
                        {
                            opacity: 0,
                            transform: 'scale(.9)'
                        }
                    ],
                    { duration: animationDuration }
                ).finished,

                overlay.animate(
                    [
                        { opacity: 1 },
                        { opacity: 0 }
                    ],
                    { duration: animationDuration }
                ).finished
            ]);

            siteSearch.hidden = true;

            input.value = '';

            await updateResults();

            document.removeEventListener('mousedown', handleDocumentMouseDown);
            document.removeEventListener('keydown', handleDocumentKeyDown);
            document.removeEventListener('focusin', handleDocumentFocusIn);
        };

        const handleInput = (): void => {
            /// debounce search queries
            clearTimeout(searchTimeout);

            searchTimeout = setTimeout(() => updateResults(input.value), searchDebounce);
        };

        const handleDocumentFocusIn = (
            event: any
        ): void => {
            /// close the panel when focus leaves the panel
            if (event.target.closest('.site-search__panel') !== panel) {
                hide();
            }
        };

        const handleDocumentMouseDown = (
            event: any
        ): void => {
            /// close the panel when clicking outside the panel
            if (event.target.closest('.site-search__overlay') === overlay) {
                hide();
            }
        };

        const handleDocumentKeyDown = (
            event: any
        ): void => {
            /// close the panel when pressing escape
            if (event.key === 'Escape') {
                event.preventDefault();

                hide();

                return;
            }

            /// handle keyboard selections
            if (['ArrowDown', 'ArrowUp', 'Home', 'End', 'Enter'].includes(event.key)) {
                event.preventDefault();

                const currentEl: HTMLLIElement = results.querySelector(
                    '[aria-selected="true"]'
                ) as HTMLLIElement;

                const items = [...Array.from(results.querySelectorAll('li') as NodeListOf<HTMLLIElement>)];
                const index = items.indexOf(currentEl);

                let nextEl: HTMLLIElement;

                if (items.length === 0) {
                    return;
                }

                switch (event.key) {
                case 'ArrowUp':
                    nextEl = items[Math.max(0, index - 1)];
                    break;
                case 'ArrowDown':
                    nextEl = items[Math.min(items.length - 1, index + 1)];
                    break;
                case 'Home':
                    nextEl = items[0];
                    break;
                case 'End':
                    nextEl = items[items.length - 1];
                    break;
                case 'Enter':
                    currentEl?.querySelector('a')
                        ?.click();
                    break;
                default:
                    break;
                }

                /// update the selected item
                items.forEach((
                    item: HTMLLIElement
                ): void => {
                    if (item === nextEl) {
                        const a: HTMLAnchorElement = nextEl.querySelector(
                            'a'
                        ) as HTMLAnchorElement;

                        panel.setAttribute('aria-activedescendant', a.id);

                        item.setAttribute('aria-selected', 'true');

                        nextEl.scrollIntoView({
                            block: 'nearest'
                        });
                    } else {
                        item.setAttribute('aria-selected', 'false');
                    }
                });
            }
        };

        const show = async (): Promise<void> => {
            document.body.style.overflow = 'hidden';

            isShowing = true;

            document.body.classList.add('site-search-visible');

            siteSearch.hidden = false;

            requestAnimationFrame(() => input.focus());

            await updateResults();

            await Promise.all([
                panel.animate(
                    [
                        {
                            opacity: 0,
                            transform: 'scale(.9)'
                        },
                        {
                            opacity: 1,
                            transform: 'scale(1)'
                        }
                    ],
                    { duration: animationDuration }
                ).finished,
                overlay.animate(
                    [
                        { opacity: 0 },
                        { opacity: 1 }
                    ],
                    { duration: animationDuration }
                ).finished
            ]);

            document.addEventListener('mousedown', handleDocumentMouseDown);
            document.addEventListener('keydown', handleDocumentKeyDown);
            document.addEventListener('focusin', handleDocumentFocusIn);
        };

        /// show the search panel when slash character is pressed outside form element
        document.addEventListener('keydown', (
            event: KeyboardEvent
        ): void => {
            /// open the search panel when slash is pressed
            const isSlash = event.key === '/';

            /// open the search panel when ctrl+k is pressed
            const isCtrlK = (event.metaKey || event.ctrlKey) && event.key === 'k';

            if (
                !isShowing
				&& (isSlash || isCtrlK)
				&& !event.composedPath().some((el: any) => ['input', 'textarea'].includes(el?.tagName?.toLowerCase()))
            ) {
                event.preventDefault();

                show();
            }
        });

        input.addEventListener('sl-input', handleInput);

        /// close the panel when a result is selected
        results.addEventListener('click', (
            event: any
        ): void => {
            if (event.target.closest('a')) {
                hide();
            }
        });

        const searchTrigger: NodeListOf<SlIconButton> = document.querySelectorAll(
            '[data-search-open]'
        ) as NodeListOf<SlIconButton>;

        if (searchTrigger) {
            searchTrigger.forEach((trigger: SlIconButton): void => {
                trigger.addEventListener('click', () => show());
            });
        }
    }
}

export default Search;
