const PasswordVisibilityToggle = (selector) => {
    const _loop = function _loop(i) {
        const passInput = selector[i].querySelector('.form-control') as HTMLInputElement;
        const passToggle = selector[i].querySelector('.password-toggle-btn') as HTMLButtonElement;

        passToggle?.addEventListener('click', (event) => {
            if ((event.target as HTMLInputElement).type !== 'checkbox') {
                return;
            }

            if ((event.target as HTMLInputElement).checked) {
                passInput.type = 'text';
            } else {
                passInput.type = 'password';
            }
        }, false);
    };

    for (let i = 0; i < selector.length; i += 1) {
        _loop(i);
    }
};

export default PasswordVisibilityToggle;
