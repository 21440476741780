const Icons:IGenerateIcons = {
    search: '<i class=\'ci-search fw-bold\'></i>',
    locate: '<i class=\'ci-location fs-22\'></i>',
    success: '<i class=\'ci-check-circle fw-bold\'></i>',
    warning: '<i class=\'ci-announcement fw-bold\'></i>',
    error: '<i class=\'ci-close-circle fw-bold\'></i>',
    retry: '<i class=\'ci-reload fw-bold\'></i>',
    prev: '<i class=\'ci-arrow-left\'></i>',
    next: '<i class=\'ci-arrow-right\'></i>',

    france_flag: '/images/icons/countries/france.svg',
    belgium_flag: '/images/icons/countries/belgium.svg',

    logo: '<svg width="64px" height="64px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1018.5 1018.5"><defs><style>.cls-1{fill:#ff6b00;}</style></defs><path class="cls-1" d="M213.93,723.61l81,81a16,16,0,0,0,22.61,0l487.05-487a16,16,0,0,0,0-22.6l-81-81a16,16,0,0,0-22.62,0L213.93,701a16,16,0,0,0,0,22.6"/><path class="cls-1" d="M317.52,213.91a16,16,0,0,0-22.61,0l-81,81a16,16,0,0,0,0,22.6L377.21,480.81,480.78,377.25Z"/><path class="cls-1" d="M804.57,701,641.18,537.57,537.61,641.21,701,804.59a16,16,0,0,0,22.62,0l81-81a16,16,0,0,0,0-22.61"/></svg>'
};

export default Icons;
