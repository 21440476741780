import 'bootstrap';

import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';

import Animate from 'components/jobs/Animate';
import Search from 'components/class/Search';
import EnableCarouselSlider from 'components/ui-elements/carousel';
import ValidationForms from 'components/forms/validation';
import SubscribeToNewsletter from 'components/forms/newsletter';
import PasswordVisibilityToggle from 'components/forms/password-visibility';
import {
    StickyNavbar, RevealNavbar
} from 'components/ui-animations/navbar';
import {
    SlButton, SlResizeObserver, SlIcon
} from '@shoelace-style/shoelace';

// import shoelace components
import '@shoelace-style/shoelace/dist/components/alert/alert.js';
import '@shoelace-style/shoelace/dist/components/avatar/avatar.js';
import '@shoelace-style/shoelace/dist/components/badge/badge.js';
import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/card/card.js';
import '@shoelace-style/shoelace/dist/components/details/details.js';
import '@shoelace-style/shoelace/dist/components/dialog/dialog.js';
import '@shoelace-style/shoelace/dist/components/divider/divider.js';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
import '@shoelace-style/shoelace/dist/components/image-comparer/image-comparer.js';
import '@shoelace-style/shoelace/dist/components/input/input.js';
import '@shoelace-style/shoelace/dist/components/resize-observer/resize-observer.js';
import '@shoelace-style/shoelace/dist/components/tab/tab.js';
import '@shoelace-style/shoelace/dist/components/tab-group/tab-group.js';
import '@shoelace-style/shoelace/dist/components/tab-panel/tab-panel.js';
import '@shoelace-style/shoelace/dist/components/textarea/textarea.js';
import '@shoelace-style/shoelace/dist/components/tooltip/tooltip.js';
import '@shoelace-style/shoelace/dist/components/visually-hidden/visually-hidden.js';

import { registerIconLibrary } from '@shoelace-style/shoelace/dist/utilities/icon-library.js';

registerIconLibrary('heroicons', {
    resolver: (
        name: SlIcon | string
    ): string => `/assets/icons/heroicons/${name}.svg`,
    mutator: (
        svg: SVGElement
    ): void => {
        svg.setAttribute('fill', 'none');
        svg.setAttribute('stroke', 'currentColor');
    }
});

registerIconLibrary('wefix', {
    resolver: (
        name: SlIcon | string
    ) => `/assets/icons/wefix/${name}.svg`
});

// Alpine.js
window.Alpine = Alpine;
Alpine.plugin(focus);
Alpine.start();

// init text animations
new Animate(
    null,
    0.3,
    false
).start();

// init search engine
Search.init();

const carousels = document.querySelectorAll('.tns-carousel .tns-carousel-inner') as NodeListOf<HTMLElement>;
EnableCarouselSlider(carousels);

const sticky = document.querySelector('.navbar-sticky') as HTMLElement;
StickyNavbar(sticky);

const reveal = document.querySelector('.navbar-reveal') as HTMLElement;
const product = document.querySelector('.navbar-product') as HTMLElement;
RevealNavbar(reveal, product);

const needs_validation = document.getElementsByClassName('needs-validation') as HTMLCollectionOf<HTMLFormElement>;
ValidationForms(needs_validation);

const form_newsletter = document.getElementsByClassName('form-newsletter') as HTMLCollectionOf<HTMLFormElement>;
SubscribeToNewsletter(form_newsletter);

const password_toggle = document.querySelectorAll('.password-toggle') as NodeListOf<HTMLElement>;
PasswordVisibilityToggle(password_toggle);

// resize observer on stepper to make it responsive
let stepper: HTMLDivElement;
stepper = document.querySelector('.wf-step') as HTMLDivElement;

if (stepper) {
    let resizeObserver: SlResizeObserver;
    resizeObserver = stepper.querySelector('sl-resize-observer') as SlResizeObserver;

    stepper.addEventListener('sl-resize', (
        event: Event
    ) => {
        const { detail } = event as Event & {
            detail: {
                entries: HTMLDivElement[]
            }
        };

        detail.entries.forEach((item: HTMLDivElement) => {
            const { contentRect } = item as HTMLDivElement & {
                contentRect: DOMRect
            };

            const { target } = item as HTMLDivElement & {
                target: HTMLDivElement
            };

            let attribute: SlButton;
            attribute = target.querySelector('sl-button') as SlButton;

            if (contentRect.width > 121.5) {
                attribute.setAttribute('size', 'medium');
            } else {
                attribute.setAttribute('size', 'small');
            }
        });
    });
}
