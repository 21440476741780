import Sal from 'sal.js';

class Animate {
    root: null;
    threshold: number;
    once: boolean;

    constructor(root: null, threshold: number, once: boolean) {
        this.root = root;
        this.threshold = threshold;
        this.once = once;

        this.start();
    }

    start() {
        Sal({
            root: this.root,
            threshold: this.threshold,
            once: this.once
        });
    }
}

export default Animate;
